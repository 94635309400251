import { useCallback, useEffect, useRef } from 'react';
import { useRouter } from 'next/router';

type Handler = (() => void) | null;

const useRouteChange = (preventUnload = false): ((node: Handler) => void) => {
  const router = useRouter();
  const handler = useRef<Handler>(null);

  const setRef = useCallback<(node: Handler) => void>((node) => {
    handler.current = node;
  }, []);

  const handleWindowClose = useCallback(
    (event: BeforeUnloadEvent) => {
      if (!handler.current) return;
      event.preventDefault();
      // eslint-disable-next-line no-param-reassign
      event.returnValue = '';
    },
    [handler],
  );

  const handleRouteChange = useCallback(() => {
    if (!handler.current) return;
    handler.current();
  }, [handler]);

  useEffect(() => {
    if (preventUnload) {
      window.addEventListener('beforeunload', handleWindowClose, { capture: true });
    }
    router.events.on('routeChangeStart', handleRouteChange);

    return () => {
      window.removeEventListener('beforeunload', handleWindowClose, { capture: true });
      router.events.off('routeChangeStart', handleRouteChange);
    };
  }, [router.events, handleRouteChange, handleWindowClose, preventUnload]);

  return setRef;
};

export default useRouteChange;
