import { InputProps as StandardInputProps } from '@mui/material/Input/Input';
import { DateTime } from 'luxon';
import { BaseTextFieldProps, TextField } from '@mui/material';
import { FastField, FastFieldProps } from 'formik';
import { useTranslation } from 'react-i18next';
import { SxProps } from '@mui/system';

type Props = {
  autoFocus?: boolean;
  disabled?: boolean;
  InputProps?: Partial<StandardInputProps & { pattern: string }>;
  isTextarea?: boolean;
  label?: string;
  name: string;
  placeholder?: string;
  required?: boolean;
  rows?: number;
  size?: BaseTextFieldProps['size'];
  sx?: SxProps;
  type?: string;
  variant?: 'outlined' | 'standard' | 'filled' | undefined;
};

function InputField(props: Props) {
  const { i18n } = useTranslation();
  const { autoFocus, disabled, name, placeholder, label, variant, isTextarea, required, rows, size, sx } = props;
  let { InputProps, type } = props;
  // https://mui.com/components/text-fields/#type-quot-number-quot
  if (type === 'number') {
    InputProps = { ...InputProps, inputMode: 'numeric', pattern: '[0-9]*' };
    type = 'text';
  }
  return (
    <FastField name={name}>
      {({ field, form, meta }: FastFieldProps) => {
        const isDisabled = typeof disabled !== 'undefined' ? disabled : form.isSubmitting;
        let myPlaceHolder;
        if (placeholder) {
          myPlaceHolder = required ? `${placeholder} *` : placeholder;
        } else {
          myPlaceHolder = required ? `${label} *` : label;
        }
        const value =
          type === 'date'
            ? DateTime.fromISO(field.value).toFormat('yyyy-MM-dd', { locale: i18n.language })
            : field.value;
        return (
          <TextField
            autoFocus={autoFocus}
            disabled={isDisabled}
            error={Boolean(meta.touched && meta.error)}
            fullWidth
            helperText={meta.touched && meta.error}
            label={label}
            margin="normal"
            multiline={isTextarea}
            name={field.name}
            onBlur={field.onBlur}
            onChange={field.onChange}
            placeholder={myPlaceHolder}
            required={required}
            rows={rows}
            size={size}
            type={type}
            value={value}
            variant={variant}
            InputProps={InputProps}
            sx={sx}
            InputLabelProps={
              type === 'date'
                ? {
                    shrink: true,
                  }
                : undefined
            }
          />
        );
      }}
    </FastField>
  );
}

InputField.defaultProps = {
  autoFocus: false,
  disabled: undefined,
  InputProps: undefined,
  isTextarea: false,
  label: '',
  placeholder: undefined,
  required: true,
  rows: 4,
  size: 'medium',
  sx: undefined,
  type: 'text',
  variant: 'outlined',
};

export default InputField;
