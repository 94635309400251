import { Box, Card, CardContent, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { useRouter } from 'next/router';

import PageLayout from '@/components/layout/PageLayout';
import FormLogin from '@/components/account/FormLogin';
import { useStore } from '@/stores';
import { withAuthSession } from '@/hooks/withAuthSession';

export const getServerSideProps = withAuthSession(
  {
    authRequired: false,
    redirect: false,
  },
  async (_, session) => {
    if (session) {
      return {
        redirect: {
          destination: '/dashboard',
          statusCode: 302,
        },
      };
    }

    return { props: {} };
  },
);

function Login() {
  const { t } = useTranslation();
  const { AccountStore } = useStore();
  const router = useRouter();

  useEffect(() => {
    const { redirect } = router.query;

    if (typeof redirect === 'string' && router.isReady) {
      AccountStore.setRedirectUrl(redirect);
    }
  }, [AccountStore, router]);

  return (
    <PageLayout title={t('pageTitle.login')} maxWidth="sm">
      <Typography color="textPrimary" gutterBottom variant="h1">
        {t('login.headline')}
      </Typography>
      <Box sx={{ my: 4 }}>
        <Card>
          <CardContent sx={{ display: 'flex', flexDirection: 'column', p: 4 }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', mb: 3 }}>
              <Typography color="textPrimary" gutterBottom variant="h4">
                {t('login.formHeadline')}
              </Typography>
            </Box>
            <Box sx={{ flexGrow: 1, mt: 3 }}>
              <FormLogin />
            </Box>
          </CardContent>
        </Card>
      </Box>
    </PageLayout>
  );
}

export default Login;
