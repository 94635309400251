import i18n from 'i18next';
import * as Yup from 'yup';

import { PublicationStatuses } from '@/types';

export const titleSchema = Yup.string().max(255).required(i18n.t('validation.titleRequired'));
export const descriptionSchema = Yup.string().max(1000).required(i18n.t('validation.descriptionRequired'));
export const publicationStatusSchema = Yup.string()
  .oneOf(Object.keys(PublicationStatuses))
  .required(i18n.t('validation.required'));
export const referenceSchema = Yup.string().required(i18n.t('validation.required'));
export const referenceOptionalSchema = Yup.string();
export const booleanSchema = Yup.boolean();
export const taxRateSchema = Yup.number().oneOf([0, 7, 19]).required(i18n.t('validation.taxRateRequired'));
