import * as Yup from 'yup';
import i18n from 'i18next';

export const streetSchema = Yup.string()
  .min(2)
  .max(255)
  .matches(/^[0-9A-Za-zÀ-ÖØ-öø-ÿ\-\s/]+$/, i18n.t('validation.street'))
  .required(i18n.t('validation.streetRequired'));
export const additionSchema = Yup.string()
  .min(2)
  .max(255)
  .matches(/^[A-Za-zÀ-ÖØ-öø-ÿ\-\s]+$/, i18n.t('validation.onlyLettersSpaceAndMinus'));
export const zipCodeSchema = Yup.string()
  .matches(/^\d+$/, i18n.t('validation.zipCodeMustBeValid'))
  .length(5, i18n.t('validation.zipCodeMustBeValid'))
  .required(i18n.t('validation.zipCodeRequired'));
export const citySchema = Yup.string()
  .min(2)
  .max(255)
  .matches(/^[A-Za-zÀ-ÖØ-öø-ÿ\-\s]+$/, i18n.t('validation.onlyLettersSpaceAndMinus'))
  .required(i18n.t('validation.cityRequired'));
export const countrySchema = Yup.string().required(i18n.t('validation.countryRequired'));

export const addressSchema = Yup.object().shape({
  addition: additionSchema,
  city: citySchema,
  street: streetSchema,
  zipCode: zipCodeSchema,
});

export const addressWithCountrySchema = Yup.object().shape({
  addition: additionSchema,
  city: citySchema,
  street: streetSchema,
  zipCode: zipCodeSchema,
  country: countrySchema,
});
