import { signIn } from 'next-auth/react';
import { useRouter } from 'next/router';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { Formik, FormikHelpers } from 'formik';
import { Alert, Box, Button } from '@mui/material';

import { emailSchema, passwordSchema } from '@/components/form/validation';
import { useStore } from '@/stores';
import { useIsMountedRef } from '@/hooks';
import InputField from '@/components/form/InputField';
import { NotificationService } from '@/services';
import { LoginCredentials } from '@/types';

const initialValues: LoginCredentials = {
  username: '',
  password: '',
};

const validationSchema = Yup.object().shape({
  username: emailSchema,
  password: passwordSchema,
});

type Props = {
  redirect?: boolean;
  onSuccess?: () => void;
};

function FormLogin({ redirect = true, onSuccess }: Props) {
  const { t } = useTranslation();
  const router = useRouter();
  const isMountedRef = useIsMountedRef();
  const { AccountStore } = useStore();
  const { redirectUrl } = AccountStore;
  const [error, setError] = useState('');

  const onSubmit = async (
    { username, password }: LoginCredentials,
    { setStatus, setSubmitting }: FormikHelpers<LoginCredentials>,
  ): Promise<void> => {
    // Sign-in with next-auth
    // Set redirect to false to be able to use custom handling
    const response = await signIn<'credentials'>('credentials', {
      username,
      password,
      redirect: false,
    });

    if (response?.ok) {
      NotificationService.success(t('login.loggedIn'));

      // Redirect to stored redirectUrl
      if (redirect) {
        const nextUrl = redirectUrl ?? '/dashboard/users';
        router.push(nextUrl).catch(console.error);
        AccountStore.setRedirectUrl(undefined);
        return;
      }

      // If redirect is false fire onSuccess
      onSuccess?.();
    } else if (response?.error) {
      setError(response?.error);
    }

    // Prevent state change on unmounted component
    if (!isMountedRef.current) return;

    setStatus({ success: response?.ok ?? false });
    setSubmitting(false);
  };

  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
      {({ handleSubmit, isSubmitting, isValid }): JSX.Element => (
        <form noValidate onSubmit={handleSubmit}>
          <InputField autoFocus label={t('generic.email')} name="username" type="email" />
          <InputField label={t('generic.password')} name="password" type="password" />
          <Box sx={{ mt: 2 }}>
            {error && (
              <Alert severity="error" sx={{ mb: 2 }}>
                {error === 'CredentialsSignin' ? t('auth.invalidCredentials') : error}
              </Alert>
            )}
            <Button
              color="primary"
              disabled={isSubmitting || !isValid}
              fullWidth
              size="large"
              type="submit"
              variant="contained"
            >
              {t('login.loginBtn')}
            </Button>
          </Box>
        </form>
      )}
    </Formik>
  );
}

FormLogin.defaultProps = {
  redirect: true,
  onSuccess: undefined,
};

export default FormLogin;
