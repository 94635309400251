import i18n from 'i18next';
import * as Yup from 'yup';

import { passwordMinLength } from '@/constants';

export const birthDateSchema = Yup.date().required(i18n.t('validation.birthDateRequired')).max(new Date());
export const emailSchema = Yup.string()
  .email(i18n.t('validation.emailMustBeValid'))
  .max(255)
  .required(i18n.t('validation.emailRequired'));
export const firstNameSchema = Yup.string()
  .min(2)
  .max(255)
  .matches(/^[A-Za-zÀ-ÖØ-öø-ÿ\-\s]+$/, i18n.t('validation.onlyLettersSpaceAndMinus'))
  .required(i18n.t('validation.firstNameRequired'));
export const lastNameSchema = Yup.string()
  .min(2)
  .max(255)
  .matches(/^[A-Za-zÀ-ÖØ-öø-ÿ\-\s]+$/, i18n.t('validation.onlyLettersSpaceAndMinus'))
  .required(i18n.t('validation.lastNameRequired'));
export const passwordSchema = Yup.string()
  .min(passwordMinLength, i18n.t('validation.passwordTooShort', { passwordMinLength }))
  .max(255)
  .required(i18n.t('validation.passwordRequired'));

export const passwordRepetitionSchema = Yup.string()
  .required(i18n.t('validation.passwordRepetitionRequired'))
  .oneOf([Yup.ref('password')], i18n.t('validation.passwordsDoNotMatch'));
